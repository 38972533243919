/* Body */
body {
    font-size: 13px;
    font-family: 'Maven Pro', sans-serif;
    line-height: 25px;
    color: #888;
}

.wrapper {
    padding-top: 110px;
    padding-bottom: 50px;
}

.border-none {
    border: medium none !important;
}
.section-padding {
    padding: 40px 0;
}
.margin-auto {
    margin: auto;
}
.dataTables_wrapper.container-fluid.dt-bootstrap4.no-footer {
    margin-top: 15px;
    padding: 0;
}
.help-block li {
    color: red;
}
.bg-success {
    background: #51aa1b none repeat scroll 0 0 !important;
}
.bg-dark {
    background: #1c2224 none repeat scroll 0 0 !important;
}
.btn-secondary {
    background: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9)) ;
    /* background: #e96125 none repeat scroll 0 0 !important; */
}
.text-secondary{
	color:#e96125 !important;
}
.badge {
    border-radius: 2px;
    font-weight: 400;
}
.btn {
    border: medium none;
    border-radius: 2px !important;
    font-size: 13px;
}
.heading-design-h5 {
    font-size: 18px;
    margin-bottom: 14px;
    color: #2b2f4c;
}

.app img {
    height: 32px;
    margin: 0 11px 0 0;
}

.login-page-bk{
    box-shadow: 0px 2px 30px #ccc6;
    padding: 30px;
}

.errorMessage {
	color: red;
	font-size: 0.75em;
}

.form-control {
    border-radius: 2px;
    font-size: 14px;
}
label {
    font-size: 13px;
    margin: 0 0 3px;
}
.modal-content {
    border: medium none;
    border-radius: 2px;
}
.login-modal-right {
    padding: 27px;
}
.login-icons {
    border: medium none;
    border-radius: 2px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}
.login-footer-tab .nav-link {
    background: #ececec none repeat scroll 0 0 !important;
    border: medium none;
    border-radius: 2px !important;
    margin: 10px 3px 0 2px;
    padding: 7px 20px;
}
.login-footer-tab .nav {
    border: medium none;
    display: inline-flex;
}

.modal-backdrop.show {
    opacity: 0.7;
}

/* Mobile Media */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .section-padding {
        padding: 0;
    }
    .category-item img {
        margin: 0px;
    }
    .wrapper {
        padding-top: 75px;
        padding-bottom: 50px;
    }
    #header-category-bk{
        padding-top: 5rem;
    }
    #header-category-bk .slick-slider .slick-arrow{
        background: none;
    }
	.carousel-slider-main .owl-prev, .carousel-slider-main .owl-next {
		top: 34%;
	}
	.account-left {
		margin-right: 0;
	}
	.account-page .mx-auto {
		margin: 0 15px !important;
	}
	.account-right {
		border-left: medium none !important;
		border-top: 1px solid #eeeeee !important;
		min-height: auto !important;
	}
	.location-top {
		display:none;
	}
	.container {
		max-width: 100%;
	}
	.navbar-toggler {
		background: #fff none repeat scroll 0 0 !important;
		border: medium none;
		border-radius: 2px;
		margin: 0 16px 0 0;
		padding: 6px 9px;
	}
	.login-modal-left {
		display: none;
	}
	.login-modal-right {
		padding: 6px;
	}
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .wrapper {
        padding-top: 169px;
        padding-bottom: 50px;
    }
	.container {
		max-width: 100%;
	}
	.navbar-toggler {
		background: #fff none repeat scroll 0 0 !important;
		border: medium none;
		border-radius: 2px;
		margin: 0 16px 0 0;
		padding: 6px 9px;
	}
	.login-modal-left {
		display: none;
	}
	.app img {
		margin-bottom: 4px;
	}

}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .wrapper {
        padding-top: 162px;
        padding-bottom: 50px;
    }
	.container {
		max-width: 100%;
	}
	.navbar-toggler {
		background: #fff none repeat scroll 0 0 !important;
		border: medium none;
		border-radius: 2px;
		margin: 0 16px 0 0;
		padding: 6px 9px;
	}
	.login-modal-left {
		display: none;
	}
	.app img {
		margin-bottom: 4px;
	}
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
	.container {
		max-width: 100%;
	}
}
